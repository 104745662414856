<template>
  <v-app>
    <!--HEADER-->
    <v-app-bar
      app
      color="primary"
      elevate-on-scroll
      dark
      clipped-left
      clipped-right
      fixed-tabs
      :src="info.background_image">
      <template v-slot:img="{ props }">
        <div style="opacity: 0.1">
          <v-img v-bind="props"/>
        </div>
      </template>
      <v-app-bar-nav-icon @click="menu = !menu" />
      <v-toolbar-title v-if="!$vuetify.breakpoint.smAndDown" class="ml-2">{{ info.name }}</v-toolbar-title>
      <v-spacer/>
      <!--TOP RIGHT MENU-->
      <v-btn @click="show_right_sidebar = !show_right_sidebar" dark icon class="mr-2">
        <v-badge v-if="record.notifications.length > 0" color="red" dot>
          <v-icon small>mdi-message</v-icon>
        </v-badge>
        <v-icon v-else small>mdi-message</v-icon>
      </v-btn>
      <small>{{ record.person.name }}</small>
      <v-menu bottom left offset-y origin="top right" transition="scale-transition">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" class="ml-2">
            <v-avatar size="40">
              <v-icon color="white">mdi-account-circle</v-icon>
            </v-avatar>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="logout">
            <v-list-item-title>{{ $store.getters.translate("logout") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <!--LEFT MENU-->
    <v-navigation-drawer
      id="main-sidebar"
      v-model="menu"
      :width="350"
      mobile-breakpoint="960"
      clipped
      app
      v-bar
      mini-variant-width="70">
      <!--MENU ITEMS-->
      <v-list>
        <v-list-item
          v-for="item in menu_items"
          @click="clickTab(item.tab)"
          :key="item.tab"
          link
          dense>
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <!--ADDITIONAL INFO AND LANGUAGE-->
      <template v-slot:append>
        <v-divider/>
        <div class="pa-5 text-center">
          <img v-if="info.logo" style="max-width: 150px" :src="info.logo"/><br />
          <small>
            <strong>{{ info.name }}</strong><br />
            <span v-if="info.address">{{ info.address }}</span><br />
            <span v-if="info.phone">{{ info.phone }}</span><br />
            <a v-if="info && info.email" :href="'mailto:' + info.email">{{ info.email }}</a><br />
          </small>
        </div>
      </template>
    </v-navigation-drawer>
    <!--BODY CONTENT-->
    <v-main style="background-color: #eef5f9">
      <v-container cols="12">
        <v-row>
          <v-col>
            <v-tabs-items style="background-color: #eef5f9" v-model="tab">
              <!--HOME TAB (FIRST, AVAILABLE FOR ALL)-->
              <v-tab-item style="background-color: #eef5f9">
                <Header :name="'home'" :icon="'mdi-home'"/>
                <!--WELCOME MESSAGE-->
                <v-container>
                  <v-skeleton-loader v-if="loading" type="card-header, article, actions,card-footer"/>
                  <v-card v-if="record.is_candidate && info.candidate_portal_welcome" class="pa-8">
                    <v-card-text>
                      <div v-html="info.candidate_portal_welcome"></div>
                    </v-card-text>
                  </v-card>
                  <v-card v-if="record.is_assessor && info.assessor_portal_welcome" class="pa-8">
                    <v-card-text>
                      <div v-html="info.assessor_portal_welcome"></div>
                    </v-card-text>
                  </v-card>
                  <v-card v-if="record.is_contact && info.contact_portal_welcome" class="pa-8">
                    <v-card-text>
                      <div v-html="info.contact_portal_welcome"></div>
                    </v-card-text>
                  </v-card>
                </v-container>
              </v-tab-item>
              <!--CONTACT INFORMATION (SECOND, AVAILABLE FOR ALL)-->
              <v-tab-item style="background-color: #eef5f9">
                <Header :name="'your_contactinformation'" :icon="'mdi-account'"/>
                <v-container fluid>
                  <base-profile
                      :save_loading="loading"
                      :module_name="page.name"
                      :record="record"
                      @change="save"
                      @clean_cache="cleanCache"
                      :user_type="record.type"
                      :profile="true"/>
                </v-container>
              </v-tab-item>
              <!--PLANNER DATA (THIRD, AVAILABLE FOR ASSESSORS-DOCENTS ONLY)-->
<!--              <v-tab-item style="background-color: #eef5f9" v-if="record.type === 'assessor'">
                <Header :name="'planner'" :icon="$icons['planner']"/>
                <v-container fluid>
                  <base-planner-assessor :assessor_name="record.person.assessor.name" :exams="exams"/>
                </v-container>
              </v-tab-item>-->
              <!--EXAMS DATA (THIRD, AVAILABLE FOR ALL EXCEPT ASSESSORS-DOCENTS)-->
              <v-tab-item style="background-color: #eef5f9" v-if="(record.type === 'assessor' && record.assessor_type !== 'docent') || record.type === 'contact' || record.type === 'candidate'">
                <Header :name="'exams'" :icon="$icons['exams']"/>
                <v-container fluid>
                  <base-exams
                      :exams="exams"
                      :user_id="record.id"
                      :user_type="record.type"
                      :assessor_type="record.assessor_type"
                      @change="load"
                      @get_exams="getExams"
                      :parent_loading="loading"
                      :info="info"
                      :all_candidates="record.candidates"/>
                </v-container>
              </v-tab-item>
              <!--CANDIDATES NOTES AND CERTIFICATES (FORTH, AVAILABLE FOR CONTACTS ONLY)-->
              <v-tab-item style="background-color: #eef5f9" v-if="record.type === 'contact'">
                <Header :name="'candidates'" :icon="$icons['candidates']"/>
                <v-container fluid>
                  <v-card class="mb-5">
                    <v-card-text>
                      <v-text-field
                          v-model="search"
                          @click:clear="clearSearch"
                          :label="$store.getters.translate('search')"
                          single-line
                          hide-details
                          clearable
                          append-icon="mdi-magnify"/>
                    </v-card-text>
                  </v-card>
                    <v-expansion-panels>
                      <v-expansion-panel v-for="(candidate, key) in $lodash.sortBy(getCandidates, 'name')" :key="key">
                        <v-expansion-panel-header>
                          <v-toolbar dense flat tile>
                            <v-toolbar-title>{{ candidate.name }}</v-toolbar-title>
                            <v-spacer/>
                            <small class="text-right">{{ candidate.companies[0].name }}</small>
                          </v-toolbar>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="pt-3 pb-3">
                          <v-data-table
                              :headers="certificate_headers"
                              :items="candidate.certificates"
                              :items-per-page="200"
                              :show-expand="false"
                              hide-default-footer
                              disable-sort>
                            <template v-slot:item.updated_at="{ item }">
                              <span v-if="item.updated_at">{{ moment(item.updated_at).format("DD-MM-YYYY HH:mm") }}</span>
                            </template>
                            <template v-slot:item.status="{ item }">
                              <span>{{ $store.getters.translate(item.status) }}</span>
                            </template>
                            <template v-slot:item.download="{ item }">
                              <v-btn v-if="item.file && item.status === 'valid' && item.transaction_succeeded" @click="download(item.file)" small color="primary">
                                {{ $store.getters.translate("download") }}
                                <v-icon right small>mdi-download</v-icon>
                              </v-btn>
                              <small v-else-if="!item.transaction_succeeded"><span class="red--text">{{ $store.getters.translate("cannot_download_certificate_not_paid") }}</span></small>
                              <small v-else><span class="red--text">{{ $store.getters.translate("cannot_download_certificate_note_valid") }}</span></small>
                            </template>
                          </v-data-table>
                          <base-notes
                            class="mt-5"
                            :notes="candidate.notes"
                            :user_id="record.id"
                            :user_type="record.type"
                            :user_phone_number="record.person.mobilenumber"
                            @change="load"/>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                </v-container>
              </v-tab-item>
              <!--NOTES DATA (FORTH, AVAILABLE FOR ASSESSORS AND CANDIDATES ONLY)-->
              <v-tab-item style="background-color: #eef5f9" v-if="(record.type === 'assessor' && record.assessor_type !== 'docent') || record.type === 'candidate'">
                <Header :name="'documents'" :icon="$icons['notes']"/>
                <v-container fluid>
                    <base-notes
                      :notes="notes"
                      :user_id="record.id"
                      :user_type="record.type"
                      :user_phone_number="record.person.mobilenumber"
                      @change="getNotes"/>
                    <span v-if="notes.length === 0">{{ $store.getters.translate("nothing_found") }}</span>
                </v-container>
              </v-tab-item>
              <!--CERTIFICATES TABLE (FIFTH, AVAILABLE FOR CANDIDATES ONLY)-->
              <v-tab-item style="background-color: #eef5f9" v-if="record.type === 'candidate'">
                <Header :name="'certificates'" :icon="$icons['certificates']"/>
                <v-container fluid>
                    <v-data-table
                      v-if="record.person && record.person.candidate && record.person.candidate.certificates"
                      :headers="certificate_headers"
                      :items="record.person.candidate.certificates"
                      :show-expand="false"
                      hide-default-footer
                      calculate-widths
                      disable-sort>
                      <template v-slot:item.updated_at="{ item }">
                        <span v-if="item.updated_at">{{ moment(item.updated_at).format("DD-MM-YYYY HH:mm") }}</span>
                      </template>
                      <template v-slot:item.status="{ item }">
                        {{ $store.getters.translate(item.status) }}
                      </template>
                      <template v-slot:item.download="{ item }">
                        <v-btn v-if="item.file && item.status === 'valid' && item.transaction_succeeded" @click="download(item.file)" x-small color="primary">
                          {{ $store.getters.translate("download") }}
                          <v-icon right x-small>mdi-download</v-icon>
                        </v-btn>
                        <small v-else-if="!item.transaction_succeeded"><span class="red--text">{{ $store.getters.translate("cannot_download_certificate_not_paid") }}</span></small>
                        <small v-else><span class="red--text">{{ $store.getters.translate("cannot_download_certificate_note_valid") }}</span></small>
                      </template>
                      <template v-slot:item.visible_contact="{ item }">
                        <v-checkbox v-model="item.visible_contact" :value="item.visible_contact" @change="updateVisibleContact(item)"/>
                      </template>
                    </v-data-table>
                </v-container>
              </v-tab-item>
              <!--BOOKS-->
<!--              <v-tab-item style="background-color: #eef5f9" v-if="record.type === 'candidate' && record.person.candidate">
                <Header :name="'books'" :icon="$icons['books']"/>
                <v-container fluid>
                  <base-books :books="books"/>
                </v-container>
              </v-tab-item>-->
              <!--FORM (SIXTH, AVAILABLE FOR CANDIDATES ONLY)-->
              <v-tab-item style="background-color: #eef5f9" v-if="record.type === 'candidate' && record.person.candidate && show_form">
                <Header :name="'personal_evaluation_form_exam'" :icon="$icons['forms']"/>
                <v-container fluid>
                  <base-form :candidate="record.person.candidate" :exam="form_exam" :template_id="template_id" @form_filled="formFilled"/>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
            <right-sidebar @refresh="load" :record="record" :show_sidebar="show_right_sidebar" @close="show_right_sidebar = false"/>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import BaseProfile from "../../../components/commonComponents/BaseProfile";
import BaseNotes from "./includes/BaseNotes";
import BaseExams from "./includes/BaseExams";
import RightSidebar from "./includes/rightSideBar";
import Header from "./includes/Header";
import helpFunctions from "../../../plugins/helpFunctions";
import BaseForm from "./includes/BaseForm";
//import BaseBooks from "./includes/BaseBooks";
//import BasePlannerAssessor from "../../../components/commonComponents/BasePlannerAssessor";

export default {
  name: "Layout",
  title: process.env.VUE_APP_TITLE ? process.env.VUE_APP_TITLE : "Perscriptum",
  components: {
    //BasePlannerAssessor,
    BaseForm,
    //BaseBooks,
    BaseProfile,
    BaseNotes,
    BaseExams,
    RightSidebar,
    Header,
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("portal"),
        name: "portal",
      },
      certificate_headers: [],
      menu_items: [
        {
          title: this.$store.getters.translate("home"),
          icon: "mdi-home",
          tab: 0,
        },
        {
          title: this.$store.getters.translate("your_contactinformation"),
          icon: "mdi-account",
          tab: 1,
        },
      ],
      show_right_sidebar: true,
      menu: !this.$vuetify.breakpoint.smAndDown,
      record: {
        language: "nl",
        notify_is_on: true,
        person: {
          first_name: "",
          insertion: "",
          last_name: "",
          initials: "",
          phonenumber: "",
          mobilenumber: "",
          emailaddresses: [],
          billingnumber: "",
          place_of_birth: "",
          country_of_birth_id: 1,
          nationality_id: 1,
          date_of_birth: "",
          gender: "",
          financial_name: "",
          assessor: {
            id: null
          },
          contact: {
            can_view_certificates: true,
            newsletter: false,
          },
          candidate: {
            id: null,
            is_dyslexic: false,
            is_dyscalc: false,
            btw_number: null,
            reference: "",
            preferred_certificate_language: "",
            certificates: [],
            notes: [],
          },
        },
        type: 'employee',
        notifications: [],
        notes: [],
        settings: { candidate_portal_welcome: null },
      },
      loading: false,
      search: "",
      tab: 0,
      info: {},
      notes: [],
      exams: [],
      //books: [],
      show_sidebar: true,
      show_form: false,
      form_exam: null,
      template_id: null,
      scheme_id: null
    };
  },
  mounted() {
    document.title = process.env.VUE_APP_TITLE;
    if (this.$vuetify.breakpoint.smAndDown) {
      this.show_right_sidebar = false;
    }
    this.load();
    this.getInfo();
  },
  methods: {
    load() {
      this.loading = true;
      let person = this.record.person;
      this.$http
        .get(this.$store.getters.appUrl + "v2/" + this.page.name)
        .then((response) => {
          this.loading = false;
          this.record = response.data;
          if (!this.record.person) {
            this.record.person = person;
          }
          if (this.record.type === "assessor") {
            if(this.record.person.assessor.type.includes("docent")) {
              this.record.assessor_type = "docent";
            }
            else if(this.record.person.assessor.type.includes("examinator")) {
              this.record.assessor_type = "examinator";
            }
            else {
              this.record.assessor_type = "supervisor";
            }
          }
          this.menu_items = helpFunctions.getMenuItems(this.record.type, this.record.assessor_type, this.show_form);
          //contact don`t need notes because he view candidates notes instead of his own
          if((this.record.type === 'assessor' && this.record.assessor_type !== 'docent') || this.record.type === 'candidate') {
            this.getNotes();
          }
          if((this.record.type === 'assessor' && this.record.assessor_type !== 'docent') || this.record.type === 'contact' || this.record.type === 'candidate') {
            this.getExams(true);
          }
          if (this.record.type === "candidate") {
            this.certificate_headers = helpFunctions.getCertificateHeaders(true);
          }
          else {
            this.certificate_headers = helpFunctions.getCertificateHeaders(false);
          }
        })
        .catch((error) => {
          this.$toasted.error(error);
          this.loading = false;
        });
    },
    download(file) {
      this.loading = true;
      this.$http
        .get(this.$store.getters.appUrl + "v2/" + this.page.name + "/file/" + file.id, { responseType: "blob" })
        .then((response) => {
          let blob;
          if(file.content_type === 'text/html') {
            blob = new Blob([response.data], {type: "text/html"});
          }
          else {
            blob = new Blob([response.data], {type: "application/pdf"});
          }
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = file.name;
          link.click();
          URL.revokeObjectURL(link.href);
          this.loading = false;
        })
        .catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    updateVisibleContact(item) {
      var visible_contact = false;
      if(item.visible_contact) {
        visible_contact = true;
      }
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/" + this.page.name + "/update-visible-contact",
              { certificate_id: item.id, visible_contact: visible_contact })
          .then((response) => {
            this.loading = false;
            if (response.status == 200) {
              this.$toasted.success(this.$store.getters.translate("successfully_saved"));
            } else {
              var errorMessage = response.data.message;
              var errors = Object.keys(response.data.message);
              this.$toasted.error([errorMessage[errors[0]]]);
            }
          })
          .catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    cleanCache() {
      this.$toasted.success("cleaned_cache");
      localStorage.clear();
      window.location.reload();
    },
    save() {
      if(!this.loading) {
        if (this.record.person && this.record.person.phonenumber && this.record.person.phonenumber.charAt(0) != '+') {
          this.$toasted.error(this.$store.getters.translate("international_number_error"));
        } else if (this.record.person && this.record.person.mobilenumber && this.record.person.mobilenumber.charAt(0) != '+') {
          this.$toasted.error(this.$store.getters.translate("international_number_error"));
        } else {
          this.loading = true;
          this.$http
              .put(this.$store.getters.appUrl + "v2/" + this.page.name, this.record)
              .then((response) => {
                this.loading = false;
                if (response.status == 200) {
                  this.$toasted.success(this.$store.getters.translate("successfully_saved"));
                  this.$store.dispatch("setLanguage", this.record.language);
                  this.$store.dispatch("setNotifyIsOn", this.record.notify_is_on);
                  this.load();
                } else {
                  var errorMessage = response.data.message;
                  var errors = Object.keys(response.data.message);
                  this.$toasted.error([errorMessage[errors[0]]]);
                }
              })
              .catch((error) => {
                if (this.$store.getters.isLoggedIn) {
                  this.$toasted.error(error);
                }
                this.loading = false;
              });
        }
      }
    },
    logout() {
      this.$store.dispatch("logout");
      return this.$router.push("/login");
    },
    async getInfo() {
      this.$vuetify.theme.themes.light.primary = "#FFFFFF";
      this.$vuetify.theme.themes.light.secondary = "#FFFFFF";
      this.$http
        .get(this.$store.getters.appUrl + "v2/info")
        .then((response) => {
          this.info = response.data;
          this.$store.dispatch("setColors", {
            primary_color: response.data.primary_color,
            secondary_color: response.data.secondary_color,
          });
          this.$vuetify.theme.themes.light.primary = response.data.primary_color;
          this.$vuetify.theme.themes.light.secondary = response.data.secondary_color;
        })
        .catch((error) => {
          this.$toasted.error(error);
          this.loading = false;
        });
    },
    async getNotes() {
      this.$http
        .get(this.$store.getters.appUrl + "v2/" + this.page.name + "/notes")
        .then((response) => {
          this.notes = response.data;
          this.loading = false;
        })
        .catch((error) => {
          this.$toasted.error(error);
          this.loading = false;
        });
    },
    async getExams(show_all_exams = true) {
      let params = "";
      if(show_all_exams) {
        params = "?show_all_exams=true";
      }
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/portal/exams" + params)
          .then((response) => {
            this.loading = false;
            this.exams = response.data;
            if(this.record.type === 'candidate' && this.record.person.candidate) {
              this.form_exam = null;
              this.show_form = false;
              for (let i = 0; i < this.exams.length; i++) {
                if(i === 0) {
                  this.scheme_id = this.exams[i].scheme_id;
                }
                this.exams[i].candidates.forEach((candidate) => {
                  if (candidate.id === this.record.person.candidate.id && candidate.pivot.form_filled === 1 && candidate.pivot.template_id) {
                    this.template_id = candidate.pivot.template_id;
                    this.form_exam = this.exams[i];
                    this.show_form = true;
                  }
                });
                if(this.show_form) {
                  break;
                }
              }
              this.menu_items = helpFunctions.getMenuItems(this.record.type, this.record.assessor_type, this.show_form);
              /*if(this.scheme_id) {
                this.getBooks();
              }*/
            }
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
    /*async getBooks() {
      this.scheme_id = 38;
      this.$http
          .post(this.$store.getters.appUrl + "v2/portal/books", {scheme_id: this.scheme_id})
          .then((response) => {
            this.loading = false;
            this.books = response.data;
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },*/
    formFilled() {
      this.tab = 0;
      this.getExams(true);
    },
    clickTab(tab) {
      this.tab = tab;
      if (this.$vuetify.breakpoint.smAndDown) {
        this.menu = false;
      }
    },
    clearSearch() {
      this.search = "";
    },
  },
  computed: {
    avatar() {
      if (this.record.person.candidate && this.record.person.file) {
        return this.record.person.file.full_path;
      }
      else {
        return null;
      }
    },
    getCandidates() {
      if(this.search.length > 1) {
        return this.record.candidates.filter((candidate) => candidate.name.toLowerCase().includes(this.search.toLowerCase()));
      }
      else {
        return this.record.candidates;
      }
    },
  },
};
</script> 

<style scoped>
.v-toolbar__image {
  opacity: 0.2 !important;
}
img {
  width: 200px;
}
</style>